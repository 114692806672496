import React from "react"
import * as Icon from "react-feather"
const navigationConfig = [
  {
    type: "groupHeader",
    groupTitle: "PAGES"
  },
  {
    id: "calendar",
    title: "Calendar",
    type: "item",
    icon: <Icon.Calendar size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/calendar",
    collapsed: false
  },
  {
    id: "exhibition",
    title: "Exhibitions",
    type: "item",
    icon: <Icon.Image size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/exhibitions",
    collapsed: false
  },
  {
    id: "artistRequest",
    title: "Artist Request",
    type: "item",
    icon: <Icon.Users size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/artist-request-list",
    collapsed: false
  },
  {
    id: "accountSettings",
    title: "Account Settings",
    type: "item",
    icon: <Icon.Settings size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/account-settings"
  },
  {
    id: "complaints",
    title: "Contact Us",
    type: "item",
    icon: <Icon.Mail size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/contact-us"
  },
  // {
  //   id: "todo",
  //   title: "Todo",
  //   type: "item",
  //   icon: <Icon.CheckSquare size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/todo/:filter",
  //   filterBase: "/todo/all"
  // },
  // {
  //   id: "calendar",
  //   title: "Calendar",
  //   type: "item",
  //   icon: <Icon.Calendar size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/calendar"
  // },
  
]

export default navigationConfig
